import React, { CSSProperties, ReactElement, useEffect, useState } from "react"

import AllianzExamVisitImage from "../../assets/images/allianz/menu/menu-exams.png"
import AllianzPrescriptionVisitImage from "../../assets/images/allianz/menu/menu-prescription.png"
import AllianzRemoveVisitImage from "../../assets/images/allianz/menu/menu-remote-consultation.png"
import AllianzSickLeaveVisitImage from "../../assets/images/allianz/menu/menu-sick-leave.png"
import AllianzStationaryVisitImage from "../../assets/images/allianz/menu/menu-stationary-visit.png"
import AllianzWomanWithComputerDisplayingChecks from "../../assets/images/allianz/visit-confirmed.png"
import AllianzCalendarAddIcon from "../../assets/svg/allianz/calendar-add.svg"
import { useAppSelector } from "../../hooks/storeHooks"
import { selectClinicSettings } from "../../store/clinic/clinicSettings.selectors"
import { MakeConsultationMenuItemValue } from "../menu/mainMenu/common/nestedMenu/makeConsultationNestedMenu/MakeConsultationMenuItems.types"

export const STATIONARY_CONSULTATION_SUCCESSFUL_PAGE = "stationaryConsultationSuccessfulPage"
export const DOCTOR_DETAILS = "doctorDetails"

interface ImageByIntegrationTypeProps {
  imageSrc: string|React.ReactElement;
  step?: number|string;
  imagePath: string|null;
  className?: string;
  additionalStyles?: CSSProperties;
  alt?: string;
  returnSvg?: boolean;
  callback?: () => any;
}

const integrationMappings: Record<string, Record<string, string>> = {
  allianz: {
    [MakeConsultationMenuItemValue.PRESCRIPTION as string]: AllianzPrescriptionVisitImage,
    [MakeConsultationMenuItemValue.EMAIL_STATIONARY_CONSULTATION as string]: AllianzStationaryVisitImage,
    [MakeConsultationMenuItemValue.STATIONARY_CONSULTATION as string]: AllianzStationaryVisitImage,
    [MakeConsultationMenuItemValue.ON_SITE_POZ_CONSULTATION as string]: AllianzStationaryVisitImage,
    [MakeConsultationMenuItemValue.REMOTE_CONSULTATION as string]: AllianzRemoveVisitImage,
    [MakeConsultationMenuItemValue.MEDICAL_EXAMINATION as string]: AllianzExamVisitImage,
    [MakeConsultationMenuItemValue.SICK_LEAVE as string]: AllianzSickLeaveVisitImage,
    [STATIONARY_CONSULTATION_SUCCESSFUL_PAGE as string]: AllianzWomanWithComputerDisplayingChecks,
    [DOCTOR_DETAILS as string]: AllianzCalendarAddIcon
  },
}

const ImageByIntegrationType: React.FC<ImageByIntegrationTypeProps> = ({
  imageSrc,
  step,
  className ,
  additionalStyles,
  alt,
  imagePath,
  returnSvg,
  callback
}) => {
  const [imageSrcState, setImageSrcState] = useState<string|null>(null)
  const [imageSvgState, setImageSvgState] = useState<string|null>(null)

  const {
    frontendSettings: { style },
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled },
    id
  } = useAppSelector(selectClinicSettings)

  const dynamicImagesEnabled = style.dynamicStylesEnabled && style.dynamicImagesEnabled
  const clinicId = id.replace("/api/clinics/", "")

  const enabledIntegrations: Record<string, boolean> = {
    allianz: ewkaVerificationEnabled || onyxVerificationEnabled
  }

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      for (const integration in enabledIntegrations) {
        if (step && enabledIntegrations[integration]) {
          const integrationMapping = integrationMappings[integration]
          if (integrationMapping && integrationMapping[step]) {
            setImageSrcState(integrationMapping[step])
            break
          }
        }
      }

      if (dynamicImagesEnabled && imagePath) {
        try {
          const imageDirectory = imagePath.endsWith(".svg") ? "svg" : "images"

          import(`../../assets/${imageDirectory}/${clinicId}/${imagePath}`).then(dynamicImageModule => {
            if (dynamicImageModule && Object.keys(dynamicImageModule).length >= 1) {
              const loadSvg = async () => {
                try {
                  const dynamicSvgModule = await import(`../../assets/${imageDirectory}/${clinicId}/${imagePath}`)

                  const response = await fetch(dynamicSvgModule.default)
                  const svgText = await response.text()

                  setImageSvgState(svgText)
                } catch (error) {}
              }

              returnSvg && loadSvg()
              !returnSvg && setImageSrcState(dynamicImageModule.default || dynamicImageModule)
            } // eslint-disable-next-line @typescript-eslint/no-empty-function
          }).catch(() => {})
        } catch (e) {}
      }
    }

    return () => {
      isMounted = false
    }
  }, [dynamicImagesEnabled, imagePath, enabledIntegrations, integrationMappings, step])

  const DynamicSVG = ({ svgContent = "" }) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(svgContent, "image/svg+xml")
    const svgElement = doc.documentElement

    const width = svgElement.getAttribute("width")
    const height = svgElement.getAttribute("height")
    const viewBox = svgElement.getAttribute("viewBox")
    const fill = svgElement.getAttribute("fill")
    const xmlns = svgElement.getAttribute("xmlns")
    const middleContent = svgElement.innerHTML

    return (
      <svg width={width ?? undefined}
        height={height ?? undefined}
        viewBox={viewBox ?? undefined}
        fill={fill ?? undefined}
        xmlns={xmlns ?? undefined}
        dangerouslySetInnerHTML={{__html: middleContent}}
        className={className}
      />
    )
  }

  const image = imageSrcState || imageSrc

  if (returnSvg) {
    if (imageSvgState) return <DynamicSVG svgContent={imageSvgState} />

    return imageSrc as ReactElement
  }

  if (typeof image !== "string") {
    return null
  }

  return (
    <img
      style={additionalStyles}
      className={className}
      src={image}
      alt={alt || "Image"}
      onClick={() => {callback && callback()}}
    />
  )
}

export default ImageByIntegrationType
