import React, { ChangeEvent,FC, useEffect } from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, TextField, Typography, useTheme } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { CancelTokenSource } from "axios"

import api, { ApiInstance } from "../../../api/api"
import { postExamsListConfig } from "../../../api/routes"
import { useAppSelector } from "../../../hooks/storeHooks"
import { analytics, LogEventType } from "../../../services/analytics"
import store from "../../../store"
import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import AppDialog from "../../common/appDialog/AppDialog.component"
import ButtonLoader from "../../common/buttonLoader/ButtonLoader.component"
import {
  getInsuranceVerificationErrorData,
  getInsuranceVerificationErrorLabel
} from "../../../pages/exams/OrderExamsPage.utils"
import { getExamOptionName } from "../../../utils/getExamOptionName"
import { getIntegrationType } from "../../../utils/getIntegrationType"
import { redirectToError500Page } from "../../../utils/handleErrors"
import { getCustomExamPriceFormat } from "../Exam.utils"
import { ExamListItem, ExamListItemResponse } from "./ExamsSelect.types"

enum ChangeReason {
  INPUT = "input", // user input
  RESET = "reset", // programmatic change
  CLEAR = "clear",
}

interface ExamsSelectProps {
  value: ExamListItem[];
  onChange(event: ChangeEvent<unknown>, newExam: null | ExamListItem): void;
  label: string;
  disabled?: boolean
  isErrorOccurred?: boolean
  errorMessage?: string
}

const ExamsSelect: FC<ExamsSelectProps> = ({onChange, label, disabled = false, isErrorOccurred, errorMessage}) => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const [query, setQuery] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [exams, setExams] = useState<ExamListItem[]>([])
  const [typingTimeout, setTypingTimeout] = useState<number>(0)
  const [noInsurancePopupErrorVisible, setNoInsurancePopupErrorVisible] = useState(false)
  const [errorType, setErrorType] = useState(null)
  const {
    patientLaboratories,
    clinicSignalIdunaSettings: { insuranceVerificationEnabled },
    frontendSettings: { modules },
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)
  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled
  const isInsuranceVerificationEnabled = insuranceVerificationEnabled || modules.includes("s7health") || isAllianzEnabled
  const contactPhoneNumber = store.getState().clinic?.clinicSettings?.frontendSettings?.contactPhoneNumber

  const handleChange = (event: ChangeEvent<unknown>, newExam: null | ExamListItem | string) => {
    if ("string" === typeof newExam) {
      return
    }
    onChange(event, newExam)
  }

  const handleInputChange = (event: ChangeEvent<unknown>, value: string, reason: string) => {
    if (
      reason === ChangeReason.CLEAR 
        || reason === ChangeReason.RESET
    ) {
      setQuery("")
      return
    }
    
    if (reason === ChangeReason.INPUT) {
      setQuery((event as ChangeEvent<HTMLInputElement>)?.target?.value || "")
      return
    }
  }

  const getExamsList = async (query: string, cancelToken?: CancelTokenSource["token"]) => {
    setLoading(true)
    setErrorType(null)
    try {
      const { data } = await api.request<ExamListItemResponse>({
        ...postExamsListConfig,
        data: {
          lang: i18n.language,
          page: 1,
          limit: 50,
          laboratory: patientLaboratories[0],
          onlyLaboratory: true,
          query
        },
        cancelToken,
      })

      const sortedItems = Array.isArray(data?.items)
        ? [...data.items]
          .filter(item => {
            if (!query) return true
            const name = getExamOptionName(item, isAllianzEnabled)?.toUpperCase() || ""
            return name.includes(query.toUpperCase())
          })
          .sort((a, b) => {
            const nameA = getExamOptionName(a, isAllianzEnabled)?.toUpperCase() || ""
            const nameB = getExamOptionName(b, isAllianzEnabled)?.toUpperCase() || ""
            return nameA.localeCompare(nameB)
          })
        : []
      
      setExams(sortedItems)
      data && analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_EXAMINATION_EXAMS_SUCCESS)
    } catch (e) {
      const errorType = e?.response?.data?.errorType

      if (api.isCancel(e)) return

      console.error(e)
      const errorLog = {
        "error_code": e.response?.status,
        "error_name": JSON.stringify(e.response?.data),
      }
      analytics.sendEventWithDefaultParams(LogEventType.DASHBOARD_EXAMINATION_EXAMS_ERROR, errorLog)

      if (errorType && isInsuranceVerificationEnabled) {
        setNoInsurancePopupErrorVisible(true)
        setErrorType(errorType)
      } else {
        redirectToError500Page(e)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getExamsList("", undefined)
      } catch (e) {}
    }

    fetchData()
  }, [])

  useEffect(() => {
    const requestSource = (api as ApiInstance).CancelToken.source()

    if (query.length > 0) {
      clearTimeout(typingTimeout)
      setTypingTimeout(
        window.setTimeout(() => {
          getExamsList(query, requestSource.token)
        }, 1000)
      )
    }
    return () => {
      setExams([])
      requestSource.cancel("Request interrupted by page change")
    }
  }, [query])

  return (
    <>
      <Autocomplete
        onChange={handleChange}
        disabled={disabled}
        loading={loading}
        loadingText={<>
          <Box display="inline-block" mx={2}>
            <ButtonLoader/>
          </Box>
          {t("exam:loadingText")}
        </>}
        options={exams}
        filterOptions={() => exams}
        getOptionLabel={(option) => getExamOptionName(option, isAllianzEnabled)}
        freeSolo
        clearText={t("exam:clear")}
        closeText={t("exam:close")}
        openText={t("exam:open")}
        handleHomeEndKeys
        onInputChange={handleInputChange}
        inputValue={query}
        noOptionsText={t("exam:noExamOptions")}
        renderOption={(option) => (
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>
              {getExamOptionName(option, isAllianzEnabled)}
            </Typography>
            {
              !isInsuranceVerificationEnabled && (
                <Box ml={1}>
                  <Typography variant="body2" color="textSecondary">
                    { getCustomExamPriceFormat(option.labPrice) }
                  </Typography>
                </Box>
              )
            }
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            InputProps={{...params.InputProps, type: "search"}}
            error={isErrorOccurred}
            helperText={errorMessage}
          />
        )}
      />
      {exams.length === 0 && query.length > 0 && (
        <Box
          bgcolor={theme.palette.common.white}
          borderRadius={theme.shape.borderRadius}
          p={2}
          mt={-0.5}
          mb={1.5}
        >
          <Typography>
            {t("exam:noExamOptions")}
          </Typography>
        </Box>
      )}
      <AppDialog
        open={noInsurancePopupErrorVisible}
        title={getInsuranceVerificationErrorLabel(
          errorType,
          getIntegrationType(
            insuranceVerificationEnabled,
            modules.includes("s7health"),
            ewkaVerificationEnabled, 
            onyxVerificationEnabled
          ),
        )}
        onClose={() => setNoInsurancePopupErrorVisible(false)}
        closeButtonText={t("close")}
        style={{whiteSpace: "pre-line"}}
      >
        {getInsuranceVerificationErrorData(
          errorType,
          getIntegrationType(
            insuranceVerificationEnabled,
            modules.includes("s7health"),
            ewkaVerificationEnabled,
            onyxVerificationEnabled
          ),
          contactPhoneNumber
        )}
      </AppDialog>
    </>
  )
}

export default ExamsSelect
