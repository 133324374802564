export interface ExamModel {
  id: string;
  consultation_id: string | null;
  created_at: string; //"2021-03-12 12:18",
  is_paid: boolean;
  status: ExamStatus;
  exam_point_address: string | null; //"ALAB: Poniatowa, Fabryczna 6",
  exam_point_name: string;
  exam_point_url: string;
  referrals: ExamReferral[];
  //--- unavailable props:
  date?: string; // scheduled date of examination
  name?: string; // name of exam category
  laboratoryName?: string;// assumption
  laboratoryAddress?: string; // assumption
  laboratoryLogo?: string; // url // assumption
  source?: ExamSource; // for STATUS_RESULTS_AVAILABLE
  source_presentation?: string; // "Wystawione z recepcji" - for STATUS_RESULTS_AVAILABLE
}

export enum ExamStatus {
  STATUS_PAID = 1,
  STATUS_PAYMENT_WAITING = 0,
  STATUS_PAYMENT_CANCELED = -1,
  STATUS_CANCELED = -2,
  STATUS_RESULTS_AVAILABLE = 2,
}

export interface ExamReferral {
  id: string;
  lab_name: string; //"Morfologia (C55)" | "Białko C-reaktywne (CRP) - ilościowe (I81)"
  result: string | null; // "przejrzyste"
  received_at: string | null; //"2021-03-20 17:40:47"
}

export interface ConsultationExamReferral {
  id: string; // "6666"
  lab_name: string; // "Glukoza"
  lab_price: string; // "9.99"
  is_bought: boolean; // true
  hl7_code?: string; //"MORF.KREW-E"
  included_in_package?: boolean;
  priceAfterDiscount?: number; // 7.8
  //TODO: check how backend enhanced response model
}

export interface UncoveredExam {
  examOrder: number;
  exception: string;
}

export enum ExamSource {
  RECEPTION = 1,
  DOCTOR = 2,
  PATIENT = 3,
}
