import React, { FC } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"

import { useAppSelector } from "../../../hooks/storeHooks"
import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import FileDropzone from "../../commonFormItems/fileDropzone/FileDropzone.component"
import GlobalFormErrorMessage from "../../commonFormItems/globalFormErrorMessage/GlobalFormErrorMessage.component"
import { SetState } from "../../../types/SetState"
import { useActiveInsuranceFormFieldsStyles } from "./ActiveInsuranceFormFields.styles"

interface ActiveInsuranceFormFieldsProps {
  handleFileChange(files: File[]): void;
  setUploadErrors: SetState<string[]>;
  uploadErrors: string[];
  insuranceVerificationEnabled: boolean;
}

const ActiveInsuranceFormFields: FC<ActiveInsuranceFormFieldsProps> = ({
  handleFileChange,
  setUploadErrors,
  uploadErrors,
  insuranceVerificationEnabled
}) => {
  const {t} = useTranslation()
  const classes = useActiveInsuranceFormFieldsStyles()
  const {
    clinicAllianzSettings: { 
      ewkaVerificationEnabled,
      onyxVerificationEnabled
    }
  } = useAppSelector(selectClinicSettings)
  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled

  if (!insuranceVerificationEnabled) {
    return null
  }

  return (
    <Controller
      name="referralConfirmation"
      render={({
        field: {onChange},
        fieldState: {error},
      }) => (
        <Box mt={3}>
          <Box className={error ? classes.error : ""}>
            <FormControlLabel
              control={
                <Checkbox
                  id="referralConfirmation"
                  onChange={onChange}
                  checkedIcon={<CheckBoxIcon color="primary"/>}
                  icon={<CheckBoxOutlineBlankIcon />}
                  size="medium"
                  className={error ? classes.error : ""}
                />
              }
              label={
                <Box>
                  <Typography variant="body1">
                    {t(`exam:${isAllianzEnabled ? "allianzReferralConfirmation" : "referralConfirmation"}`)}
                  </Typography>
                </Box>
              }
            />
          </Box>
          { error && (
            <GlobalFormErrorMessage message={error?.message || ""}/>
          )}
          {!isAllianzEnabled && (
            <Box mt={1.5}>
              <FileDropzone
                apiErrors={uploadErrors}
                resetApiErrors={() => setUploadErrors([])}
                handleInputChange={handleFileChange}
                customClassUploadDropzone={classes.dropzone}
              />
            </Box>
          )}
        </Box>
      )}
    />
  )
}

export default ActiveInsuranceFormFields
