import { createStyles, makeStyles } from "@material-ui/core"

import { Theme } from "@material-ui/core/styles"

export const useOrderExamsListStyles = makeStyles<Theme, { isDisabledRemove: boolean; }>((theme: Theme) => createStyles({
  discountPrice: {
    margin: 0,
    color: theme.palette.success.main
  },
  removeButton: {
    "& path": {
      stroke: props => props.isDisabledRemove 
        ? theme.palette.grey[200] 
        : theme.palette.text.primary
    }
  }
}))
