import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {List, ListItem, ListItemText} from "@material-ui/core"

import AppDialog from "../../common/appDialog/AppDialog.component"
import {ExamReferral} from "../Exam.types"

interface ComingExamReferralsModalProps {
  open: boolean;
  onClose(): void;
  referrals: ExamReferral[];
}

const ComingExamReferralsModal: FC<ComingExamReferralsModalProps> = ({open, onClose, referrals}) => {
  const {t} = useTranslation()
  const referralsCount = referrals.length

  return (
    <AppDialog
      open={open}
      onClose={onClose}
      title={t("exam:examReferrals")}
    >
      <List>
        {
          [...referrals]
            .sort((a, b) => a.lab_name.localeCompare(b.lab_name))
            .map((referral, index) => (
              <ListItem
                key={`${referral.id}${index}`}
                divider={index !== referralsCount - 1}
              >
                <ListItemText primary={referral.lab_name}/>
              </ListItem>
            ))
        }
      </List>
    </AppDialog>
  )
}

export default ComingExamReferralsModal
