import React, { FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Box } from "@material-ui/core"

import useGetFormValidationRules from "../../../hooks/useGetFormValidationRules"
import { useHandleReactHookFormErrors } from "../../../hooks/useHandleReactHookFormErrors"
import {
  userForcePasswordChangeValidationSchema
} from "../../user/userProfile/userForcePasswordChange/UserForcePasswordChange.validation"
import Button from "../../button/Button.component"
import GlobalFormErrorMessageHandlers
  from "../../commonFormItems/globalFormErrorMessageHandlers/GlobalFormErrorMessageHandlers.component"
import PasswordInputController
  from "../../commonFormItems/passwordInputController/PasswordInputController.component"
import { ForceChangePasswordPayload } from "../../user/userProfile/userForcePasswordChange/UserForcePasswordChange.types"
import { useResetPasswordFormStyles } from "./ResetPasswordForm.styles"

import { dataTestIdMap } from "./ResetPasswordForm.config"

export type ResetPasswordFormProps = {
  onSubmit: (changePasswordPayload: ForceChangePasswordPayload) => Promise<unknown>
  isLoading: boolean;
  error?: unknown
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit, isLoading, error }) => {
  const {t} = useTranslation()
  const classes = useResetPasswordFormStyles()
  const form = useForm<ForceChangePasswordPayload>({
    resolver: useGetFormValidationRules(userForcePasswordChangeValidationSchema),
  })

  useHandleReactHookFormErrors(form, error)

  return (
    <FormProvider {...form}>
      <Box
        display="flex"
        flexDirection="column"
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <GlobalFormErrorMessageHandlers error={error}/>
        <PasswordInputController
          name="plainPassword"
          label={t("user:plainPassword")}
          placeholder={t("user:plainPassword")}
          helperText={t("user:plainPasswordRequirements")}
          data-testid={dataTestIdMap.plainPasswordInput}
        />
        <PasswordInputController
          name="plainPasswordRepeat"
          label={t("user:plainPasswordRepeat")}
          placeholder={t("user:plainPasswordRepeat")}
          data-testid={dataTestIdMap.plainPasswordRepeatInput}
        />
        <Button
          type="submit"
          isLoading={isLoading}
          className={classes.button}
          data-testid={dataTestIdMap.submitButton}
        >
          {t("save")}
        </Button>
      </Box>
    </FormProvider>
  )
}

export default ResetPasswordForm
