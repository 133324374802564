import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"

import { ReactComponent as TrashIcon } from "../../../assets/svg/trash.svg"
import { useAppSelector } from "../../../hooks/storeHooks"
import { selectClinicSettings } from "../../../store/clinic/clinicSettings.selectors"
import ImageByIntegrationType from "../../imageByIntegrationType/ImageByIntegrationType"
import { getExamOptionName } from "../../../utils/getExamOptionName"
import { isNotNullish } from "../../../utils/isNotNullish"
import { getCustomExamPriceFormat,getExamPriceFormat } from "../Exam.utils"
import { ExamListItem } from "../examsSelect/ExamsSelect.types"
import { useOrderExamsListStyles } from "./OrderExamsList.styles"

interface OrderedExamsListProps {
  exams: (ExamListItem & { priceAfterDiscount?: number })[];
  removeExam(examId: number): void
}

const OrderedExamsList: FC<OrderedExamsListProps> = ({ exams, removeExam }) => {
  const { t } = useTranslation()
  const { 
    clinicSignalIdunaSettings: { insuranceVerificationEnabled }, 
    frontendSettings: { modules },
    clinicAllianzSettings: { ewkaVerificationEnabled, onyxVerificationEnabled }
  } = useAppSelector(selectClinicSettings)
  const isAllianzEnabled = ewkaVerificationEnabled || onyxVerificationEnabled
  const isInsuranceVerificationEnabled = insuranceVerificationEnabled || modules.includes("s7health") || isAllianzEnabled
  const classes = useOrderExamsListStyles({ isDisabledRemove: false })

  const examsTotalPrice = exams.reduce(
    (price, exam) => price + (exam.priceAfterDiscount ?? parseFloat(exam.labPrice)),
    0
  )

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow hover>
              <TableCell component="th"> {t("exam:index")} </TableCell>
              <TableCell component="th"> {t("exam:labName")} </TableCell>
              { !isInsuranceVerificationEnabled && 
                  <TableCell component="th" align="right">
                    {t("exam:labPrice")}
                  </TableCell>
              }
              <TableCell component="th" align="right"> </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { exams.map((exam, index) => (
              <TableRow hover key={exam.id}>
                <TableCell>
                  {index + 1}
                </TableCell>

                <TableCell>
                  {getExamOptionName(exam, isAllianzEnabled)}
                </TableCell>

                {
                  !isInsuranceVerificationEnabled && (
                    <TableCell align="right">
                      {
                        isNotNullish(exam.priceAfterDiscount)
                          ? (
                            <div>
                              <s>{ getCustomExamPriceFormat(exam.labPrice) }</s>
                              <p className={classes.discountPrice}>
                                { getExamPriceFormat(exam.priceAfterDiscount) + "\u00A0zł" }
                              </p>
                            </div>
                          )
                          : <>{ getCustomExamPriceFormat(exam.labPrice) }</>
                      }
                    </TableCell>
                  )
                }

                <TableCell align="right">
                  <IconButton
                    className={classes.removeButton}
                    size="small"
                    onClick={() => removeExam(exam.id)}
                    disabled={false}
                  >
                    <ImageByIntegrationType
                      imageSrc={<TrashIcon/>}
                      imagePath={"trash.svg"}
                      returnSvg={true}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {
        !isInsuranceVerificationEnabled && (
          <Box p={4} display="flex" justifyContent="space-between">
            <Typography>
              {t("exam:summary")}
            </Typography>

            <Typography>
              {t("exam:price")}: { getExamPriceFormat(examsTotalPrice) } zł
            </Typography>
          </Box>
        )
      }
    </>
  )
}

export default OrderedExamsList
